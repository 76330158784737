<template>
    <div>
        <div class="content-main">
            <div class="content-header">
                <h2>比赛详情</h2>
            </div>
            <div>
                <el-divider></el-divider>
            </div>
            <TInfo v-if="info.id > 0" :info="info"></TInfo>
        </div>
    </div>
</template>

<script>
    import TInfo from './components/tournament-info'
    export default {
        name: "detail",
        components:{TInfo},
        methods: {

        },
        data() {
            return {
                info: {}
            }
        },
        created() {
            this.info = this.$route.query.info;
        }
    }
</script>

<style scoped>
    .content-main{
        width: 100%;
        margin: 0 auto;
        background-color: white;
        padding: 20px 0;
    }
    .content-header{
        text-align: center;
    }
</style>
